import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import { BreadcrumbExample } from '../../examples/react/BreadcrumbExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Breadcrumb"
    components={[{ component: Breadcrumb }, { component: BreadcrumbItem }]}
    status={[{ type: 'accessible', version: '14.2.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          <Code>Breadcrumb</Code> asetellaan sivuotsikon yläpuolelle.
        </ListItem>
        <ListItem>
          Murupolkua ei käytetä, jos aktiivinen sivu on päänavigaation
          ensimmäisellä tasolla.
        </ListItem>
      </List>
      <Playground example={BreadcrumbExample} />
      <Playground format="html">
        <>
          <nav aria-label="Murupolku">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <LinkMock>Etusivu</LinkMock>
              </li>
              <li className="breadcrumb-item">
                <LinkMock>Alasivu</LinkMock>
              </li>
              <li
                aria-current="page"
                className="breadcrumb-item"
              >
                Ala-alasivu
              </li>
            </ol>
          </nav>
          <h1 className="mb-0">Sivun pääotsikko</h1>
        </>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Semanttisesti komponentti tuottaa linkkilistan. Murupolun alapuolelle
        tuleva otsikko on sivun pääotsikko ja sen tasoksi merkitään H1.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
