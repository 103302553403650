import React from 'react'

import { Breadcrumb, BreadcrumbItem, Heading, Link } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

const BreadcrumbExample = () => (
  <>
    <Breadcrumb ariaLabel="Murupolku">
      <BreadcrumbItem>
        <Link as={LinkMock}>Etusivu</Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <Link as={LinkMock}>Alasivu</Link>
      </BreadcrumbItem>
      <BreadcrumbItem active>Ala-alasivu</BreadcrumbItem>
    </Breadcrumb>
    <Heading
      level={1}
      noMargin
    >
      Sivun pääotsikko
    </Heading>
  </>
)

export { BreadcrumbExample }
